import PropTypes from 'prop-types';
import './button.scss';
import { ButtonHTMLAttributes, ReactElement } from 'react';

interface ButtonInterface extends ButtonHTMLAttributes<HTMLButtonElement> {
  color: string,
  format: string,
  size: string, 
  children: ReactElement|string,
  className: string,
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({ size, color, format, className, children, ...props }: ButtonInterface) => {
  // Get button color
  let btnColor = "btn--default"
  switch (color) {
    case "default":
      btnColor = "btn--default"
      break;
    case "white":
      btnColor = "btn--white"
      break;
    case "primary":
      btnColor = "btn--primary"
      break;
    case "secondary":
      btnColor = "btn--secondary"
      break;
    case "warning":
      btnColor = "btn--warning"
      break;
    case "danger":
      btnColor = "btn--danger"
      break;
    case "success":
      btnColor = "btn--success"
      break;
    default:
      btnColor = ""
      break;
  }

  // Get button format
  let btnFormat = "btn--rounded--default"
  switch (format) {
    case "rounded":
      btnFormat = "btn--rounded"
      break;
    case "default":
      btnFormat = "btn--rounded--default"
      break;
    case "icon":
      btnFormat = "btn--icon"
      break;
    default:
      btnFormat = ""
      break;
  }

  
  // Get button size
  let btnSize = ""
  switch (size) {
    case "small":
      btnSize = "btn--small"
      break;
    case "medium":
      btnSize = "btn--medium"
      break;
    case "large":
      btnSize = "btn--large"
      break;
    default:
      btnSize = ""
      break;
  }

  return (
    <button
      type="button"
      className={['btn', btnColor, btnFormat, btnSize, className].join(' ')}
      {...props}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Button color
   */
  color: PropTypes.string,
  /**
   * Button format
   */
  format: PropTypes.string,
  /**
   * Button format
   */
  classname: PropTypes.string,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Optional click handler
   */
  children: PropTypes.node,
};

Button.defaultProps = {
  size: 'medium',
  format: 'default',
  color: 'default',
  className: "",
  children: null,
  onClick: undefined,
};
